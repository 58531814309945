import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

export const routesWithTitles = [
  { path: '/login', title: 'My Brightspeed - Login' },
  { path: '/login-password', title: 'My Brightspeed - Login' },
  { path: '/signUp', title: 'My Brightspeed - Create account' },
  { path: '/new-forget-username', title: 'My Brightspeed - Forgot username' },
  { path: '/home ', title: 'My Brightspeed - Home' },
  { path: '/view-payments', title: 'My Brightspeed - View payments' },
  { path: '/payment-methods', title: 'My Brightspeed - Payment methods' },
  { path: '/account-info', title: 'My Brightspeed - Account settings' },
  { path: '/plan-details', title: 'My Brightspeed - Manage my service' },
  { path: '/update-plan', title: 'My Brightspeed - Change your plan' },
  { path: '/network-info ', title: 'My Brightspeed - Network settings' },
  { path: '/help-and-support ', title: 'My Brightspeed - We’re here to help' },
  { path: '/appointments ', title: 'My Brightspeed - Appointments' },
];

const getPageTitle = (pathname: string) => {
  const route = routesWithTitles.find(r => pathname?.includes(r.path.trim()));
  return route ? route.title : 'Brightspeed';
};

const PageTitleChanger: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    const title = getPageTitle(location.pathname);
    document.title = title;
  }, [location.pathname]);

  const pageTitle = getPageTitle(location.pathname);

  return (
    <Helmet>
      <title>{pageTitle}</title>
    </Helmet>
  );
};

export default PageTitleChanger;