import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import rootReducer from "./rootReducer";
import { FLUSH, persistStore,PURGE } from "redux-persist"; // Import persistStore
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    process.env.REACT_APP_ENV !== "production" && process.env.REACT_APP_ENABLE_LOGS === "true"
    ? getDefaultMiddleware({
      serializableCheck: false, // Disable serialization checks globally
    }).concat(logger)
  : getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [PURGE,FLUSH]
      }, // Disable serialization checks globally
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

const persistor = persistStore(store); // Create the persistor instance

export { persistor };  // Named export for persistor
export default store;  // Default export for store


