export const ENVIRONMENT = {
  staging: {
    customAPIUrl: "https://stoplight.io/mocks/brightspeed-apiverse/",
    otherUrl1: "",
    otherUrl2: "",
    liveUrl: "https://digitalservicesdev.brightspeed.com/",
    contentStackUrl: "https://cdn.contentstack.io/v3/content_types",
    brightspeedApi: "https://api-dv.brightspeed.com",
    quickBillPayment:
      "https://digitalservicenextcopperdev.brightspeed.com/digital-payment/api/paymentms/order/quickBillPay",

    validateUserIAmAPIUrl: "https://graph.microsoft.com/v1.0/users",
    IAM_login_url:
      "https://BrightspeedMMTestB2C.b2clogin.com/BrightspeedMMTestB2C.onmicrosoft.com/B2C_1A_ROPC_Auth_Brightspeed_ADB2C/oauth2/v2.0/token",
    IAM_invalidate_RefreshTokens: "https://graph.microsoft.com/v1.0/users",
    //paypalAPI_Url: "https://api-3t.sandbox.paypal.com",
    paypalAPI_Url: "https://api-dv.brightspeed.com",
    IAM_graphAPI_token:
      "https://login.microsoftonline.com/BrightspeedMMTestB2C.onmicrosoft.com/oauth2/v2.0/token",
    IAM_graph_API_url: "https://graph.microsoft.com/v1.0",
    /* sessionManagement: "https://api-dev.brightspeed.com/digital/sessionid", */
    sessionManagement: "https://api-dv.brightspeed.com/bass/v1/session",
    encryptionUrl: "https://api-dv.brightspeed.com/digital/v1/accountmgmt/ency",
    neverBounceUrl: "https://api.neverbounce.com/v4/single/",
    ams_base_url: "https://api-dv.brightspeed.com",
    ams_token_url: "https://api-qa.brightspeed.com/digital/v1/dev/amsId",
    axon_url: "https://apgx-dev.brightspeed.com",
    aem_content_url: "https://publish-p56078-e404378.adobeaemcloud.com",
    aem_content_brspd_url: "https://brspd-stage.brightspeed.com",
    chatstartscriptSrc: 'https://chatservices-qa.brightspeed.com/chatStartupScript.js',
    chatstartscriptChatparamJwthost: 'https://apgx-uat.brightspeed.com/cc-jws-generator',
    chatstartscriptChatparamCCaiphost: 'https://brspd-sit-ccaip-fdnnfsw.ue1.ccaiplatform.com',
    chatstartscriptChatparamCompanyID: '1669137598978574827316fc646fe5ed2',
    chatstartscriptChatparamMenuKey: 'BrightspeedChatSit',
    chatControlscriptSrc: 'https://chatservices-qa.brightspeed.com/controller/chat-controller.js',
    // not_eligible_QBP_payment : "https://publish-p56078-e404378.adobeaemcloud.com/",
    // eligible_QBP_payment : "https://publish-p56078-e404378.adobeaemcloud.com/"
  },
  QA: {
    customAPIUrl: "https://stoplight.io/mocks/brightspeed-apiverse/",
    otherUrl1: "",
    otherUrl2: "",
    liveUrl: "https://digitalservicesqa1.brightspeed.com/",
    liveUrlUAT: "https://digitalservicesuat.brightspeed.com",
    liveUrlUATNew: "https://api-qa.brightspeed.com",
    contentStackUrl: "https://cdn.contentstack.io/v3/content_types",
    brightspeedApi: "https://api-qa.brightspeed.com",
    quickBillPayment:
      "https://digitalservicesnextdev.brightspeed.com/digital-payment/api/paymentms/order/quickBillPay",
    //brightspeedApi1:"https://api-dev.brightspeed.com",
    validateUserIAmAPIUrl: "https://graph.microsoft.com/v1.0/users",
    IAM_login_url:
      "https://BrightspeedMMTestB2C.b2clogin.com/BrightspeedMMTestB2C.onmicrosoft.com/B2C_1A_ROPC_Auth_Brightspeed_ADB2C/oauth2/v2.0/token",
    IAM_invalidate_RefreshTokens: "https://graph.microsoft.com/v1.0/users",
    paypalAPI_Url: "https://api-dv.brightspeed.com",
    IAM_graphAPI_token:
      "https://login.microsoftonline.com/BrightspeedMMTestB2C.onmicrosoft.com/oauth2/v2.0/token",
    IAM_graph_API_url: "https://graph.microsoft.com/v1.0",
    encryptionUrl: "https://api-qa.brightspeed.com/digital/v1/accountmgmt/ency",
    sessionManagement: "https://api-qa.brightspeed.com/digital/v1/sessionid",
    neverBounceUrl: "https://api.neverbounce.com/v4/single/",
    ams_base_url: "https://api-qa.brightspeed.com",
    ams_token_url: "https://api-qa.brightspeed.com/digital/v1/amsId",
    axon_url: "https://apgx-dev.brightspeed.com",
    aem_content_url: "https://publish-p56078-e432648.adobeaemcloud.com/",
    aem_content_brspd_url: "https://brspd-stage.brightspeed.com",
    // chatstartscriptSrc: 'https://chatservices-dv.brightspeed.com/chatStartupScript.js',
    // chatstartscriptChatparamJwthost: "https://apgx-dev.brightspeed.com/cc-jws-generator",
    // chatstartscriptChatparamCCaiphost: "https://brspd-dev-ccaip-70dj2d3.ue1.ccaiplatform.com/",
    // chatstartscriptChatparamCompanyID: "166913585003974588122c3bd9631be86",
    // chatstartscriptChatparamMenuKey:'BrightspeedChat',
    // chatControlscriptSrc: 'https://chatservices-dv.brightspeed.com/controller/chat-controller.js',
    chatstartscriptSrc: 'https://chatservices-qa.brightspeed.com/chatStartupScript.js',
    chatstartscriptChatparamJwthost: 'https://apgx-uat.brightspeed.com/cc-jws-generator',
    chatstartscriptChatparamCCaiphost: 'https://brspd-sit-ccaip-fdnnfsw.ue1.ccaiplatform.com',
    chatstartscriptChatparamCompanyID: '1669137598978574827316fc646fe5ed2',
    chatstartscriptChatparamMenuKey: 'BrightspeedChatSit',
    chatControlscriptSrc: 'https://chatservices-qa.brightspeed.com/controller/chat-controller.js',
    // not_eligible_QBP_payment : "https://publish-p56078-e404378.adobeaemcloud.com/",
    // eligible_QBP_payment : "https://publish-p56078-e404378.adobeaemcloud.com/"
  },
  E2E: {
    customAPIUrl: "https://stoplight.io/mocks/brightspeed-apiverse/",
    otherUrl1: "",
    otherUrl2: "",
    liveUrl: "https://digitalservicesqa1.brightspeed.com/",
    liveUrlUAT: "https://digitalservicesuat.brightspeed.com",
    liveUrlUATNew: "https://api-e2e.brightspeed.com",
    contentStackUrl: "https://cdn.contentstack.io/v3/content_types",
    brightspeedApi: "https://api-e2e.brightspeed.com",
    quickBillPayment:
      "https://digitalservicesnextdev.brightspeed.com/digital-payment/api/paymentms/order/quickBillPay",
    //brightspeedApi1:"https://api-dev.brightspeed.com",
    validateUserIAmAPIUrl: "https://graph.microsoft.com/v1.0/users",
    IAM_login_url:
      "https://BrightspeedMMTestB2C.b2clogin.com/BrightspeedMMTestB2C.onmicrosoft.com/B2C_1A_ROPC_Auth_Brightspeed_ADB2C/oauth2/v2.0/token",
    IAM_invalidate_RefreshTokens: "https://graph.microsoft.com/v1.0/users",
    paypalAPI_Url: "https://api-dv.brightspeed.com",
    IAM_graphAPI_token:
      "https://login.microsoftonline.com/BrightspeedMMTestB2C.onmicrosoft.com/oauth2/v2.0/token",
    IAM_graph_API_url: "https://graph.microsoft.com/v1.0",
    encryptionUrl:
      "https://api-e2e.brightspeed.com/digital/v1/accountmgmt/ency",
    sessionManagement: "https://api-e2e.brightspeed.com/digital/v1/sessionid",
    neverBounceUrl: "https://api.neverbounce.com/v4/single/",
    ams_base_url: "https://api-e2e.brightspeed.com",
    ams_token_url: "https://api-e2e.brightspeed.com",
    axon_url: "https://apgx-dev.brightspeed.com",
    aem_content_url: "https://publish-p56078-e432648.adobeaemcloud.com",
    aem_content_brspd_url: "https://brspd-stage.brightspeed.com",
    chatstartscriptSrc: 'https://chatservices-qa.brightspeed.com/chatStartupScript.js',
    chatstartscriptChatparamJwthost: 'https://apgx-uat.brightspeed.com/cc-jws-generator',
    chatstartscriptChatparamCCaiphost: 'https://brspd-sit-ccaip-fdnnfsw.ue1.ccaiplatform.com',
    chatstartscriptChatparamCompanyID: '1669137598978574827316fc646fe5ed2',
    chatstartscriptChatparamMenuKey: 'BrightspeedChatSit',
    chatControlscriptSrc: 'https://chatservices-qa.brightspeed.com/controller/chat-controller.js',
  },
  uat: {
    customAPIUrl: "https://uat.stoplight.io/mocks/brightspeed-apiverse/",
    otherUrl1: "",
    otherUrl2: "",
    liveUrl: "https://digitalservicesdev.brightspeed.com",
    contentStackUrl: "https://cdn.contentstack.io/v3/content_types",
    brightspeedApi: "https://api-ut.brightspeed.com",
    quickBillPayment:
      "https://digitalservicesnextdev.brightspeed.com/digital-payment/api/paymentms/order/quickBillPay",
    validateUserIAmAPIUrl: "https://graph.microsoft.com/v1.0/users",
    IAM_login_url:
      "https://BrightspeedMMProdB2C.b2clogin.com/BrightspeedMMProdB2C.onmicrosoft.com/B2C_1A_ROPC_Auth_Brightspeed_ADB2C/oauth2/v2.0/token",
    IAM_invalidate_RefreshTokens: "https://graph.microsoft.com/v1.0/users",
    paypalAPI_Url: "https://api-ut.brightspeed.com",
    IAM_graphAPI_token:
      "https://login.microsoftonline.com/BrightspeedMMTestB2C.onmicrosoft.com/oauth2/v2.0/token",
    IAM_graph_API_url: "https://graph.microsoft.com/v1.0",
    encryptionUrl:
      "https://api-ut.brightspeed.com/digital/v1/accountmgmt/ency",
    sessionManagement: "https://api-ut.brightspeed.com/digital/v1/sessionid",
    neverBounceUrl: "https://api.neverbounce.com/v4/single/",
    ams_base_url: "https://api-pr.brightspeed.com",
    ams_token_url: "https://api-pr.brightspeed.com/digital/v1/amsId",
    axon_url: "https://apgx-dev.brightspeed.com",
    aem_content_url: "https://publish-p56078-e432647.adobeaemcloud.com",
    // aem_content_brspd_url: "https://www.brightspeed.com",
    aem_content_brspd_url: "https://brspd-stage.brightspeed.com",
    chatstartscriptSrc: 'https://chatservices-qa.brightspeed.com/chatStartupScript.js',
    chatstartscriptChatparamJwthost: 'https://apgx-uat.brightspeed.com/cc-jws-generator',
    chatstartscriptChatparamCCaiphost: 'https://brspd-sit-ccaip-fdnnfsw.ue1.ccaiplatform.com',
    chatstartscriptChatparamCompanyID: '1669137598978574827316fc646fe5ed2',
    chatstartscriptChatparamMenuKey: 'BrightspeedChatSit',
    chatControlscriptSrc: 'https://chatservices-qa.brightspeed.com/controller/chat-controller.js'
    // not_eligible_QBP_payment : "https://publish-p56078-e404378.adobeaemcloud.com/",
    // eligible_QBP_payment : "https://publish-p56078-e404378.adobeaemcloud.com/"
  },
  production: {
    customAPIUrl: "https://stoplight.io/mocks/brightspeed-apiverse/",
    otherUrl1: "",
    otherUrl2: "",
    liveUrl: "https://digitalservices.brightspeed.com/",
    contentStackUrl: "https://cdn.contentstack.io/v3/content_types",
    brightspeedApi: "https://api-pr.brightspeed.com",
    validateUserIAmAPIUrl: "https://graph.microsoft.com/v1.0/users",
    IAM_login_url:
      "https://BrightspeedMMProdB2C.b2clogin.com/BrightspeedMMProdB2C.onmicrosoft.com/B2C_1A_ROPC_Auth_Brightspeed_ADB2C/oauth2/v2.0/token",
    IAM_invalidate_RefreshTokens: "https://graph.microsoft.com/v1.0/users",
    paypalAPI_Url: "https://api-pr.brightspeed.com",
    IAM_graphAPI_token:
      "https://login.microsoftonline.com/BrightspeedMMTestB2C.onmicrosoft.com/oauth2/v2.0/token",
    IAM_graph_API_url: "https://graph.microsoft.com/v1.0",
    sessionManagement: "https://api-pr.brightspeed.com/digital/v1/sessionid",
    encryptionUrl: "https://api-pr.brightspeed.com/digital/v1/accountmgmt/ency",
    neverBounceUrl: "https://api.neverbounce.com/v4/single/",
    ams_base_url: "https://api-pr.brightspeed.com",
    ams_token_url: "https://api-pr.brightspeed.com/digital/v1/amsId",
    axon_url: "https://apgx-dev.brightspeed.com",
    aem_content_url: "https://publish-p56078-e432647.adobeaemcloud.com",
    aem_content_brspd_url: "https://www.brightspeed.com",
    chatstartscriptChatparamJwthost:"https://apgx.brightspeed.com/cc-jws-generator",
    chatstartscriptChatparamCCaiphost: "https://brspd-prd-ccaip-h2e71d6.ue1.ccaiplatform.com/",
    chatstartscriptChatparamCompanyID: "16691379530624442e9730a35fcd8dfcf",
    chatstartscriptChatparamMenuKey: 'BrightspeedChatPrd',
    chatControlscriptSrc: "https://chatservices.brightspeed.com/controller/chat-controller.js",
    chatstartscriptSrc: 'https://chatservices.brightspeed.com/chatStartupScript.js',

  },
};

export const setEnvironment = (env) => {
  // process.env.REACT_APP_ENV will be available only in web app
  if (!process.env.REACT_APP_ENV) {
    process.env.NODE_ENV = env;
  }
};

export const getEnvironment = () => {
  return (
    ENVIRONMENT[process.env.REACT_APP_ENV] ||
    ENVIRONMENT[process.env.NODE_ENV] ||
    ENVIRONMENT.production
  );
};
