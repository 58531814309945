import { PayloadAction, createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAddons, validateVoicePortingTelephone, validateVoicePortingData } from "../../../../shared/hooks/useFetchServiceDetails";
import API_URLS from "../../../../shared/utils/ApiUrls";
import store from "../store";
import { PURGE } from "redux-persist";


interface DefaultVoipObject {
  productFamily: any;
  voipProductSelected?: boolean;
  id?: string;
  productCode?: string;
  productName?: string;
  priceResult?: Array<any>;
  billingRateProductId?: string;
}

type getAccordionStateInitialStateType = {
  cartResponse: any;
  selectedAccordion: number;
  voipProducts: any;
  validatePhoneNUmberResponse: any;
  voicePortingData: any;
  voiceRemoved: boolean;
  currentProductData: any;
  baseline: any;
  voipphone: any;
  voipFiberAgreement: any;
  voipServiceLimitationAgreement: boolean;
  voipLnpAgreement: any;
  productFlag: boolean;
  voiceResetted: any;
  isLnpChoosen: string;
  cartKey: any;
  openBroadband: boolean;
  voiceAdded: any;
  VoipProductName: any;
  cancelVoice: boolean;
  VoipProductPrice: any;
  InstallationProductPrice: any;
  InstallationProductName: any;
  voipProductId: any;
  InternetProductPrice: any;
  InternetProductName: any;
  InternetProductId: any;
  productType: string
  tierKey: string,
  loading: boolean;
  error: any;
  addonsProduct: any;
  discountChangeModalStatus: boolean;
  notEligibleModalStatus: boolean;
  discountName: string;
  discountAmount: any;
  isAffinityFromCatalogue: any;
  currentVoiceProduct: DefaultVoipObject;
  selectedInternetChanged: boolean;
  backToPlan: boolean;
  voipInfo: any;
  sortedVoiceProduct: any;
  speedChanged: any,
  planChangedAction: any;
  internetChangeOrder:any;
  voiceChangeOrder: any;
  vasChangeOrder:any;
  newVoiceOrder:any;
  voicePorted:any;
  orderNumber:any;
  oneGfiberProduct: any;
  twoGfiberProduct :any;
  vasProductCode:any;
  vasChecked: boolean;
  vasAgreeChecked: boolean;
  existingSpeed: number;
  voiceLineRemoved: string;
  voiceRemovedLineitemId: string;
  previousCartResponse: any;
}
const initialState: getAccordionStateInitialStateType = {
  selectedAccordion: 1,
  voipProducts: {},
  validatePhoneNUmberResponse: [],
  voicePortingData: null,
  voiceRemoved: false,
  currentProductData: {},
  baseline: {},
  addonsProduct: {},
  voipphone: "",
  voipFiberAgreement: "",
  voipServiceLimitationAgreement: false,
  voipLnpAgreement: '',
  productFlag: false,
  voiceResetted: '',
  isLnpChoosen: "No",
  cartKey: '',
  openBroadband: false,
  voiceAdded: false,
  VoipProductName: '',
  cancelVoice: false,
  VoipProductPrice: '',
  InstallationProductPrice: '',
  InstallationProductName: '',
  voipProductId: '',
  InternetProductPrice: '',
  InternetProductName: '',
  InternetProductId: '',
  productType: "",
  tierKey: "",
  loading: false,
  error: null,
  discountChangeModalStatus: false,
  notEligibleModalStatus: false,
  discountName: "",
  discountAmount: "",
  isAffinityFromCatalogue: undefined,
  currentVoiceProduct: {
    "voipProductSelected": false,
    "id": "",
    "productCode": "",
    "priceResult": [],
    "billingRateProductId": "",
    "productName": "",
    productFamily: "",
  },
  selectedInternetChanged: false,
  backToPlan: false,
  cartResponse: null,
  sortedVoiceProduct: {},
  speedChanged: "",
  planChangedAction: [],
  internetChangeOrder: false,
  voiceChangeOrder: false,
  vasChangeOrder: false,
  newVoiceOrder: false,
  voicePorted: false,
  twoGfiberProduct: false,
  oneGfiberProduct: false,
  vasProductCode: {},
  vasChecked: false,
  vasAgreeChecked: false,
  orderNumber: "",
  voipInfo: {
    earliestCRD: "",
    latestCRD: "",
    lnp: "",
    wirelessPort: "",
    accountHolderName: "",
    accountId: "",
    pinNumber: "",
    agreement: "",
    portAvailablityList: "",
  },
  existingSpeed: 0,
  voiceLineRemoved: "",
  voiceRemovedLineitemId: "",
  previousCartResponse: undefined
}

export const getAddonProducts = createAsyncThunk(
  "getAddsOnProduct/shopExp",
  async (addOnsProductsRequest : any) => {
    try {
     const reduxStore = store.getState();
     let headers = reduxStore?.brightspeedToken?.headers;
     headers = {...headers, flow:"change"}
     const params = {
       "": [API_URLS?.addonsProducts],
     };
 
     const { sessionId } = reduxStore?.user;
     const siteId=reduxStore?.addressQualification?.data?.addressQualification?.entity?.siteId;
     const wireCenter = reduxStore?.addressQualification?.data?.addressQualification?.entity?.wireCenter;
     const transactionId= reduxStore?.addressQualification?.data?.addressQualification?.entity?.partnerSessionId;
     const channel= process.env.REACT_APP_PLAN_CHANNEL_ID;
 
     const baseline = {
           "transactionId": transactionId,
           "channel": channel,
           "wirecenter": wireCenter,
           "siteId": siteId,
           "partnerOrderId": transactionId,
           "intent": ""
         };
 
     const payloads = {baseline, addOnsProductsRequest}
     const response = await getAddons(params, payloads, headers);
     return response.data.addOnsProductResponse;
    } catch (error) {
     throw(error);
    }
   }
 );

export const validatePortingTelephone  = createAsyncThunk(
  "validatePhoneNumber/shopExp",
  async (payloadData : any) => {
    console.log(payloadData,"payload1");
    try {
      const reduxStore = store.getState();
      const headers = reduxStore?.brightspeedToken?.headers;
      console.log(API_URLS?.validatePortingTelephone,"params1");
      const params = {
        "": [API_URLS?.validatePortingTelephone],
      };
      console.log(params,"params");
      const response = await validateVoicePortingTelephone(params, payloadData, headers);
      console.log(`validatephoneNumber:: phoneNumber: `, response?.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const validatePortingData  = createAsyncThunk(
  "validatePortData/shopExp",
  async (payloadData : any) => {
    try {
      const reduxStore = store.getState();
      const headers = reduxStore?.brightspeedToken?.headers; 
      const params = {
        "": [API_URLS?.voicePortingData],
      };
      
      const response = await validateVoicePortingData(params, payloadData, headers);
      const messageStatus = response?.data?.messageStatus?.find((item: any) => {
          if ("statusCode" in item) {
              return item;
          } 
      })
      if (messageStatus?.statusCode == 200) {
          return response?.data;
      } 
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const getAccordionStateSlice = createSlice({
  name: "getAccordionState/Slice",
  initialState,
  reducers: {
    getAccordionState: (state, action) => {
      state.selectedAccordion = action.payload;
    },
    selectedPlanType : (state,action: PayloadAction<string>) =>{
      state.productType = action.payload;
    },
    setVoiceRemoved: (state, action: PayloadAction<boolean>) => {
      state.voiceRemoved = action.payload;
    },
    setVoipInfo: (state, action: PayloadAction<any>) => {
      state.voipInfo = {
        ...state.voipInfo,
        ...action.payload}
    },
    setVoipPhone: (state, action: PayloadAction<string>) => {
      state.voipphone = action.payload;
    },
    setVoipFiberAgreement: (state, action: PayloadAction<any>) => {
      state.voipFiberAgreement = action.payload;
    },
    setVoipServiceLimitationAgreement: (state, action: PayloadAction<any>) => {
      state.voipServiceLimitationAgreement = action.payload;
    },
    setVoipLnpAgreement: (state, action: PayloadAction<boolean>) => {
      state.voipLnpAgreement = action.payload;
    },
    setProductFlag: (state, action: PayloadAction<any>) => {
      state.productFlag = action.payload;
    },
    setVoiceResetted: (state, action: PayloadAction<any>) => {
      state.voiceResetted = action.payload;
    },
    setIsLnpChoosen: (state, action: PayloadAction<any>) => {
      state.isLnpChoosen = action.payload;
    },
   
    setOpenBroadband: (state, action: PayloadAction<any>) => {
      state.openBroadband = action.payload;
    },
    setVoiceAdded: (state, action: PayloadAction<any>) => {
      state.voiceAdded = action.payload;
    },
    setCancelVoice: (state, action: PayloadAction<any>) => {
      state.cancelVoice = action.payload;
    },
    setVoipProductName: (state, action: PayloadAction<any>) => {
      state.VoipProductName = action.payload;
    },
    setVoipProductPrice: (state, action: PayloadAction<any>) => {
      state.VoipProductPrice = action.payload;
    },
    setInstallationProductPrice: (state, action: PayloadAction<any>) => {
      state.InstallationProductPrice = action.payload;
    },
    setInstallationProductName: (state, action: PayloadAction<any>) => {
      state.InstallationProductName = action.payload;
    },
    setVoipProductId: (state, action: PayloadAction<any>) => {
      state.voipProductId = action.payload;
    },
    setInternetProductPrice: (state, action: PayloadAction<any>) => {
      state.InternetProductPrice = action.payload;
    },
    setInternetProductName: (state, action: PayloadAction<any>) => {
      state.InternetProductName = action.payload;
    },
    setInternetProductId: (state, action: PayloadAction<any>) => {
      state.InternetProductId = action.payload;
    },
    setvalidatePhoneNUmberResponse: (state,action:PayloadAction<any>)=>{
      state.validatePhoneNUmberResponse = action.payload
    },
    setvoicePortingData:(state,action:PayloadAction<any>)=>{
      state.voicePortingData = action.payload
    },
    setCurrentProductData: (state,action:PayloadAction<any>)=>{
      state.currentProductData = action.payload
    },
    setCurrentVoiceProduct: (state,action:PayloadAction<any>)=>{
      state.currentVoiceProduct = action.payload
    },
    setDiscountChangedModal:(state,action:PayloadAction<any>)=>{
      state.discountChangeModalStatus = action.payload
    },
    setNotEligibleModal:(state,action:PayloadAction<any>)=>{
      state.notEligibleModalStatus = action.payload
    },
    setDiscountAmount:(state,action:PayloadAction<any>)=>{
      state.discountAmount = action.payload
    },
    setDiscountName:(state,action:PayloadAction<any>)=>{
      state.discountName = action.payload
    },
    setIsAffinityFromCatalogue:(state,action:PayloadAction<any>)=>{
      state.isAffinityFromCatalogue = action.payload
    },
    setSelectedInternetChanged:(state,action:PayloadAction<any>)=>{
      state.selectedInternetChanged = action.payload
    },
    setBackToPlanSelection:(state,action:PayloadAction<any>)=>{
      state.backToPlan = action.payload
    },
    setSortedVoiceProduct:(state,action:PayloadAction<any>)=>{
      state.sortedVoiceProduct = action.payload
    },
    setChangedSpeed:(state,action:PayloadAction<any>)=>{
      state.speedChanged = action.payload
    },
    setExistingSpeed:(state,action:PayloadAction<any>)=>{
      state.existingSpeed = action.payload
    },
    setPlanChanged:(state,action:PayloadAction<any>)=>{
      state.planChangedAction = action.payload
    },
    setInternetChangeOrder:(state,action:PayloadAction<any>)=>{
      state.internetChangeOrder = action.payload
    },
    setVoiceChangeOrder:(state,action:PayloadAction<any>)=>{
      state.voiceChangeOrder = action.payload
    },
    setAddonsChangeOrderAdded:(state,action:PayloadAction<any>)=>{
      state.vasChangeOrder = action.payload
    },
    setNewVoiceChangeOrder:(state,action:PayloadAction<any>)=>{
      state.newVoiceOrder = action.payload
    },
    setVoicePorted:(state,action:PayloadAction<any>)=>{
      state.voicePorted = action.payload
    },
    setChangeOrderNumber:(state,action:PayloadAction<any>)=>{
      state.orderNumber = action.payload
    },
    setTwoGfiberProduct:(state,action:PayloadAction<any>)=>{
      state.twoGfiberProduct = action.payload
    },
    setoneGfiberProduct:(state,action:PayloadAction<any>)=>{
      state.oneGfiberProduct = action.payload
    },
    setVASProduct:(state,action:PayloadAction<any>)=>{
      state.vasProductCode = action.payload
    },
    setVASChecked:(state,action:PayloadAction<any>)=>{
      state.vasChecked = action.payload
    },
    setVasAgreeChecked:(state,action:PayloadAction<any>)=>{
      state.vasAgreeChecked = action.payload
    },
    setVoiceLineRemoved(state, action: PayloadAction<any>) {
      state.voiceLineRemoved = action.payload;
    },
    setVoiceRemovedLineItemId(state, action: PayloadAction<any>) {
      state.voiceRemovedLineitemId = action.payload;
    },
    setPreviousCartResponse:(state,action:PayloadAction<any>)=>{
      state.previousCartResponse = action.payload
    },

    },
  extraReducers: (builder) => {
   
    builder.addCase(validatePortingTelephone.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(validatePortingTelephone.fulfilled, (state, action) => {
      state.validatePhoneNUmberResponse = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(validatePortingTelephone.rejected, (state, action) => {
      state.validatePhoneNUmberResponse = null;
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(validatePortingData.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(validatePortingData.fulfilled, (state, action) => {
      state.voicePortingData = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(validatePortingData.rejected, (state, action) => {
      state.voicePortingData = null;
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(getAddonProducts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAddonProducts.fulfilled, (state, action) => {
      state.addonsProduct = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getAddonProducts.rejected, (state, action) => {
      state.addonsProduct = null;
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(PURGE, () => {
      return  initialState;
    })
  },
})

  export const {
    getAccordionState,
    setVoiceRemoved,
    setVoipInfo,
    setVoipPhone,
    setVoipFiberAgreement,
    setVoipServiceLimitationAgreement,
    setVoipLnpAgreement,
    setProductFlag,
    setVoiceResetted,
    setIsLnpChoosen,
    setOpenBroadband,
    setVoiceAdded,
    setCancelVoice,
    setVoipProductName,
    setVoipProductPrice,
    setInstallationProductPrice,
    setInstallationProductName,
    setVoipProductId,
    setInternetProductPrice,
    setInternetProductName,
    setInternetProductId,
    // baseLine,
    selectedPlanType,
    setCurrentProductData,
    setvalidatePhoneNUmberResponse,
    setvoicePortingData,
    setDiscountChangedModal,
    setNotEligibleModal,
    setDiscountAmount,
    setDiscountName,
    setIsAffinityFromCatalogue,
    setCurrentVoiceProduct,
    setSelectedInternetChanged,
    setBackToPlanSelection,
    setSortedVoiceProduct,
    setChangedSpeed,
    setPlanChanged,
    setInternetChangeOrder,
    setVoiceChangeOrder,
    setNewVoiceChangeOrder,
    setAddonsChangeOrderAdded,
    setVoicePorted,
    setChangeOrderNumber,
    setTwoGfiberProduct,
    setoneGfiberProduct,
    setVASProduct,
    setVASChecked,
    setVasAgreeChecked,
    setVoiceLineRemoved,
    setVoiceRemovedLineItemId,
    setExistingSpeed,
    setPreviousCartResponse
  } = getAccordionStateSlice.actions;
  export default getAccordionStateSlice.reducer;
  
  